import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FindInPage from '@mui/icons-material/FindInPage';
import { scroller } from 'react-scroll';
import useActiveCountry from './hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';

const PageFloatingActionMenu = ({ page }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const [open, setOpen] = useState(false);
    const [navItems, setNavItems] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const navigate = to => {
        scroller.scrollTo(to, {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -65,
        });
        handleClose();
    };

    useEffect(() => {
        const actions = [];
        if (page) {
            const nis = page?.PageSections?.map((t, i) => {
                const anchorId = t?.Title.replace(' ', '-').toLowerCase();
                return { icon: <FindInPage />, name: t?.Title, to: anchorId };
            });

            actions.push({ icon: <FindInPage />, name: ct('Text_About.Text'), to: 'about' });
            if (page?.TakeAction) {
                actions.push({ icon: <FindInPage />, name: ct('Text_TakeAction.Text'), to: 'takeaction' });
            }

            nis.forEach(ni => {
                actions.push(ni);
            });

            if (page?.Checklist && page?.Checklist.length > 0) {
                actions.push({ icon: <FindInPage />, name: ct('Text_Checklist.Text'), to: 'checklist' });
            }
            setNavItems(actions);
        }
    }, []);

    return (
        <SpeedDial
            ariaLabel="Quick Menu"
            color="secondary"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
        >
            {navItems.map(action => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => {
                        navigate(action.to);
                    }}
                />
            ))}
        </SpeedDial>
    );
};

PageFloatingActionMenu.propTypes = {
    page: PropTypes.object,
};

export default PageFloatingActionMenu;
