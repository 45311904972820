import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import useCacheStore from '../shared/hooks/useCacheStore';
import { StorageKeys } from '../../constants/Storage';
import { decode } from 'html-entities';
import { Spin } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import useActiveCountry from './hooks/useActiveCountry';
import ContentSection from './ContentSection';
import DefaultAccordion from './DefaultAccordion';
import PagePanel from './PagePanel';
import PageLink from './PageLink';
import BoundButton from './BoundButton';
import TakeActionBox from './TakeActionBox';
import useConfig from '../../config/hooks/useConfig';
import './css/boundpage.less';
import PageFloatingActionMenu from './PageFloatingActionMenu';

const BoundPage = ({ isHome = false }) => {
    const c = useActiveCountry();
    const { ct, asset } = useCountryTranslation(c);
    const History = useHistory();
    const { pageName } = useParams();
    const { data } = useCacheStore(StorageKeys.PageContentKey);
    const [page, setPage] = useState();
    const [hasCheckedForPage, setHasCheckedForPage] = useState(false);
    const config = useConfig();
    const checklistLink = `${config.assetUrl}${page?.ChecklistFile}`;
    const calcPageName = pageName == '' || pageName == null ? 'home' : pageName;
    const navItems = page?.PageSections?.map((t, i) => {
        const anchorId = t?.Title.replace(' ', '-').toLowerCase();
        return <PageLink key={i.toString()} text={t?.Title} to={anchorId} />;
    });

    const handleBackClick = () => {
        History.goBack();
    };

    useEffect(() => {
        document.title = `${ct('Text_RespondToAbuse.Text')} | ${page?.Title}`;
    }, [page]);

    useEffect(() => {
        const total = Object.entries(data)?.length;
        let count = 0;
        for (const [key, value] of Object.entries(data)) {
            const pageData = value;
            if (!isHome && pageData?.Url?.toLowerCase() === calcPageName?.toLowerCase()) {
                setPage(pageData);
            }
            if (isHome && pageData?.IsHome == 'True') {
                setPage(pageData);
            }
            count++;
            if (total >= count) {
                setHasCheckedForPage(true);
            }
        }
    }, [setPage, setHasCheckedForPage, data, pageName]);

    if (page == null && hasCheckedForPage === false) {
        return <Spin />;
    }

    if (page == null && hasCheckedForPage === true) {
        return (
            <div>
                <br />
                <br />
                <h1>{ct('Text_PageNotFound.Text')}</h1>
                <br />
                <br />
            </div>
        );
    }

    return (
        <PagePanel>
            <div id="primary-fom">
                <PageFloatingActionMenu page={page} />
            </div>
            <div className="split-page">
                {!isHome && page?.DisplayLeftNavigation === 'True' && (
                    <div className="page-left">
                        <div className="ln-root">
                            <PageLink text={ct('Text_About.Text')} to="about" />
                            {navItems}
                            {page?.TakeAction && <PageLink text={ct('Text_TakeAction.Text')} to="takeaction" />}
                            {page?.Checklist && page?.Checklist.length > 0 && <PageLink text={ct('Text_Checklist.Text')} to="checklist" />}
                        </div>
                    </div>
                )}

                <div className="page-right">
                    <div id="about" name="about">
                        <h1>{page?.Title}</h1>
                        {page?.Content && <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(page?.Content) }}></p>}
                    </div>

                    {page?.PageSections?.map((t, i) => {
                        return (
                            <div key={i.toString()} className="pad-bottom">
                                <ContentSection title={t?.Title} content={t?.Content} />
                            </div>
                        );
                    })}

                    {page?.TakeAction && page?.TakeAction.length > 0 && (
                        <div id="takeaction" name="takeaction">
                            {!isHome && <h1>{ct('Text_TakeAction.Text')}</h1>}
                            {page?.TakeActionDescription && (
                                <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(page?.TakeActionDescription) }}></p>
                            )}
                            <div className="ta pad-bottom">
                                {page?.TakeAction.map((t, i) => {
                                    return (
                                        <TakeActionBox
                                            key={i.toString()}
                                            title={decode(t?.Title)}
                                            content={decode(t?.Description)}
                                            headColor={t?.HeaderColor}
                                            bodyColor={t?.BodyColor}
                                            link={t?.Link}
                                            icon={t?.Icon}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {page?.Checklist && page?.Checklist.length > 0 && (
                        <div id="checklist" name="checklist" className="pad-bottom">
                            <h1>{ct('Text_Checklist.Text')}</h1>
                            {page?.ChecklistDescription && (
                                <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(page?.ChecklistDescription) }}></p>
                            )}
                            <DefaultAccordion items={page?.Checklist} />
                            {page?.ChecklistFile != null && page?.ChecklistFile !== '' && (
                                <div className="checklist-actions">
                                    <a href={checklistLink} download="Checklist.pdf" target="_blank">
                                        <BoundButton className="secondary-bg" binding="Text_Download.Text" padded={false} />
                                    </a>
                                </div>
                            )}
                        </div>
                    )}

                    <div className="addtl-content pad-bottom">
                        {page?.AdditionalContent && <p dangerouslySetInnerHTML={{ __html: decode(page?.AdditionalContent) }}></p>}
                    </div>

                    {page?.DisplayBackButton === 'True' && (
                        <div className="back-button-container">
                            <BoundButton binding="Text_Back.Text" icon={faChevronLeft} onClick={handleBackClick} />
                        </div>
                    )}
                </div>
            </div>
        </PagePanel>
    );
};

BoundPage.propTypes = {
    isHome: PropTypes.bool,
};

export default BoundPage;
