import React from 'react';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltToRight, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import BoundButton from '../shared/BoundButton';
import './css/navigation.less';

function SubNavigation() {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);

    const onLinkClick = () => {
        window.location.href = 'https://bbc.com';
    };

    const handleContactClick = () => {
        window.location.href = `/${l}/contact`;
    };

    return (
        <div className="container">
            <div className="link-button" onClick={handleContactClick}>
                <div className="icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="text">{ct('Text_ContactAdviceLine.Text')}</div>
            </div>
            <div className="link-button">
                <BoundButton icon={faArrowAltToRight} className="secondary-bg" text={ct('Text_QuickExit.Text')} onClick={onLinkClick} />
            </div>
        </div>
    );
}

export default SubNavigation;
