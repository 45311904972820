import React from 'react';
import PropTypes from 'prop-types';
import './css/pagepanel.less';

const PagePanel = ({ children, background }) => {
    return (
        <div style={{ background: background }} className="page-panel">
            <div className="inner">{children}</div>
        </div>
    );
};

PagePanel.propTypes = {
    full: PropTypes.bool,
    background: PropTypes.string,
};

export default PagePanel;
