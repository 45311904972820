import { useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useActiveCountry from './useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';

const useCacheStore = (storeKey, useTranslation = true) => {
    const [cacheStore, setCacheStore] = useState({});
    const c = useActiveCountry();
    const { l, n } = useCountryTranslation(c);

    useEffect(() => {
        const fetchCacheStoreData = async () => {
            const cachedContent = await AsyncStorage.getItem(storeKey);
            const rawCacheData = JSON.parse(cachedContent);
            if (useTranslation) {
                const translatedStoreData = rawCacheData[l][n];
                setCacheStore(translatedStoreData);
            } else {
                setCacheStore(rawCacheData);
            }
        };

        fetchCacheStoreData();
    }, [setCacheStore]);

    return {
        data: cacheStore,
    };
};

export default useCacheStore;
