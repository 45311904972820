import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { decode } from 'html-entities';
import './css/boundaccordion.less';

const DefaultAccordion = ({ items }) => {
    const { Panel } = Collapse;

    const mappedItems =
        items && items.length > 0 ? (
            items.map((v, i) => (
                <Panel key={i.toString()} header={decode(v.Title)}>
                    <p dangerouslySetInnerHTML={{ __html: decode(v.Content) }}></p>
                </Panel>
            ))
        ) : (
            <></>
        );

    return <Collapse defaultActiveKey={['0']}>{mappedItems}</Collapse>;
};

DefaultAccordion.propTypes = {
    items: PropTypes.array.isRequired,
};

export default DefaultAccordion;
