import React, { useEffect, useState } from 'react';
import { Affix, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { StorageKeys } from '../../constants/Storage';
import useCacheStore from '../shared/hooks/useCacheStore';
import NavigationDrawer from './NavigationDrawer';
import NavigationItems from './NavigationItems';
import SubNavigation from './SubNavigation';
import './css/navigation.less';

const { Header } = Layout;

function TopNavigation() {
    const c = useActiveCountry();
    const { l } = useCountryTranslation(c);
    const { data } = useCacheStore(StorageKeys.PageContentKey);
    const [navLinks, setNavLinks] = useState([]);

    useEffect(() => {
        const newNavLinks = [];
        for (const [key, value] of Object.entries(data)) {
            const pageData = value;
            if (value?.IsHome == 'False' && value?.DisplayInNavigation == 'True') {
                newNavLinks.push({
                    title: pageData?.Title,
                    url: pageData?.Url,
                });
            }
        }
        setNavLinks(newNavLinks);
    }, [setNavLinks, data]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Affix>
            <Header className="header">
                <div className="menu">
                    <div className="nav-text">
                        <Link to={`/${l}/home`} onClick={scrollToTop}>
                            <img height={50} src={require('../../assets/images/logo.png')} />
                        </Link>
                    </div>
                    <div className="main-menu">
                        <NavigationDrawer navLinks={navLinks} />
                        <NavigationItems navLinks={navLinks} />
                    </div>
                </div>
            </Header>
            <div id="subheader">
                <SubNavigation />
            </div>
        </Affix>
    );
}

export default TopNavigation;
