import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import './css/boundbutton.less';
import useActiveCountry from './hooks/useActiveCountry';
import { decode } from 'html-entities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BoundButton = ({ binding, text, type = 'primary', onClick, disabled, icon, iconRight, className = 'addtl-class', htmlType, padded = true }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const iconElement =
        icon != null ? (
            <span style={{ paddingRight: 10 }}>
                <FontAwesomeIcon icon={icon} />
            </span>
        ) : null;
    const buttonText = binding != null ? decode(ct(binding)) : text;
    const margin = padded ? 8 : 0;

    return (
        <Button
            icon={iconElement}
            className={`bound-button ${type} ${className}`}
            style={{ marginLeft: margin }}
            disabled={disabled}
            onClick={onClick}
            htmlType={htmlType}
        >
            {buttonText}
            {iconRight && <FontAwesomeIcon style={{ marginLeft: 12 }} icon={iconRight} />}
        </Button>
    );
};

BoundButton.propTypes = {
    binding: PropTypes.string,
    type: PropTypes.oneOf(['primary', 'secondary']),
    onClick: PropTypes.func,
    icon: PropTypes.any,
    iconRight: PropTypes.any,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    text: PropTypes.string,
    htmlType: PropTypes.string,
    padded: PropTypes.bool,
};

export default BoundButton;
