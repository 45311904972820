import PropTypes from 'prop-types';
import React from 'react';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { decode } from 'html-entities';
import { animateScroll } from 'react-scroll';
import useQuestionnaireContext from './hooks/useQuestionnaireContext';
import BoundButton from './BoundButton';
import './css/boundquestionnaire.less';

const PossibleAnswers = ({ qIndex, possibleAnswers }) => {
    const { recordAnswer, answerNames } = useQuestionnaireContext();
    return possibleAnswers != null
        ? possibleAnswers.map((a, i) => {
              let icon = null;
              if (answerNames[qIndex] == a.Title) {
                  icon = faCheckCircle;
              }

              const saveAnswer = () => {
                  recordAnswer(qIndex, a.Value, a.Title);
                  animateScroll.scrollMore(155);
              };
              return <BoundButton key={i.toString()} icon={icon} text={decode(a.Title)} type="primary" onClick={saveAnswer} />;
          })
        : [];
};

PossibleAnswers.propTypes = {
    binding: PropTypes.number,
    possibleAnswers: PropTypes.array,
};

export default PossibleAnswers;
