import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import useWebAnalyticsContext from '../../shared/hooks/useWebAnalyticsContext';
import { Events, Origins, Templates } from 'brightsky-3';

const initialState = {};

export const QuestionnaireContext = createContext(initialState);

export const QuestionnaireContextProvider = ({ children }) => {
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
    const [currentQuestion, setCurrentQuestion] = useState();
    const [answers, setAnswers] = useState([]);
    const [answerNames, setAnswerNames] = useState([]);
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);

    const reset = () => {
        setCurrentQuestion();
        setCurrentQuestionIndex(0);
        setCorrectAnswerCount(0);
        setAnswers([]);
        setAnswerNames([]);
    };

    const selectQuestionnaire = q => {
        setSelectedQuestionnaire(q);
    };

    const startQuestionnaire = q => {
        setSelectedQuestionnaire(q);
        setCurrentQuestionIndex(0);
        setCurrentQuestion(q.Questions[0]);
    };

    const advanceQuestion = () => {
        const nextQuestionIndex = currentQuestionIndex + 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setCurrentQuestion(selectedQuestionnaire.Questions[nextQuestionIndex]);
    };

    const previousQuestion = () => {
        const nextQuestionIndex = currentQuestionIndex - 1;
        setCurrentQuestionIndex(nextQuestionIndex);
        setCurrentQuestion(selectedQuestionnaire.Questions[nextQuestionIndex]);
    };

    const isComplete = () => {
        if (
            selectedQuestionnaire &&
            currentQuestionIndex &&
            selectedQuestionnaire &&
            currentQuestionIndex === selectedQuestionnaire.Questions.length
        ) {
            return true;
        }

        return false;
    };

    const recordAnswer = (index, answer, answerName) => {
        const newAnswers = [...answers];
        newAnswers[index] = answer;
        setAnswers(newAnswers);
        const newAnswerNames = [...answerNames];
        newAnswerNames[index] = answerName;
        setAnswerNames(newAnswerNames);
        if (index >= currentQuestionIndex) {
            advanceQuestion();
        }
    };

    const scoreQuestionnaire = () => {
        let correctCount = 0;
        answers.forEach((a, i) => {
            const result = a === selectedQuestionnaire.Questions[i].Answer;
            if (result) {
                correctCount += 1;
            }
        });
        setCorrectAnswerCount(correctCount);
    };

    const resetAnswers = () => {
        setAnswers([]);
        setCorrectAnswerCount(0);
    };

    return (
        <QuestionnaireContext.Provider
            value={{
                selectedQuestionnaire,
                setSelectedQuestionnaire,
                currentQuestionIndex,
                setCurrentQuestionIndex,
                currentQuestion,
                setCurrentQuestion,
                answers,
                setAnswers,
                answerNames,
                setAnswerNames,
                correctAnswerCount,
                setCorrectAnswerCount,
                reset,
                selectQuestionnaire,
                startQuestionnaire,
                scoreQuestionnaire,
                previousQuestion,
                resetAnswers,
                recordAnswer,
                isComplete,
            }}
        >
            {children}
        </QuestionnaireContext.Provider>
    );
};

QuestionnaireContextProvider.propTypes = {
    children: PropTypes.node,
};
