import React from 'react';
import PropTypes from 'prop-types';
import { decode } from 'html-entities';

const ContentSection = ({ title, content }) => {
    const anchorId = title.replace(' ', '-').toLowerCase();

    return (
        <div id={anchorId} name={anchorId} className="content-section">
            <h1>{title}</h1>
            <p dangerouslySetInnerHTML={{ __html: decode(content) }}></p>
            <br />
        </div>
    );
};

ContentSection.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
};

export default ContentSection;
