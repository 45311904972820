import React from 'react';
import PropTypes from 'prop-types';
import useConfig from '../../config/hooks/useConfig';

const HeroBox = ({ altText, image }) => {
    const config = useConfig();
    const imageLink = image ? `${config.assetUrl}/${image}` : '';

    return (
        <div className="hb-root">
            <div className="hb-image">
                <img src={imageLink} alt={altText} />
            </div>
        </div>
    );
};

HeroBox.propTypes = {
    altText: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default HeroBox;
