import React, { useEffect, useState } from 'react';
import useCacheStore from './hooks/useCacheStore';
import { StorageKeys } from '../../constants/Storage';
import { decode } from 'html-entities';
import { Spin } from 'antd';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from './hooks/useActiveCountry';
import DefaultAccordion from './DefaultAccordion';
import PagePanel from './PagePanel';
import './css/boundpage.less';
import PageLink from './PageLink';
import TakeActionBox from './TakeActionBox';

const BoundContactPage = () => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const { data } = useCacheStore(StorageKeys.ContactPageKey, false);
    const [page, setPage] = useState();
    const title = page?.Title && page?.Title[l];
    const phoneTitle = page?.PhoneTitle && page?.PhoneTitle[l];
    const emailTitle = page?.EmailTitle && page?.EmailTitle[l];
    const faqTitle = page?.FAQTitle && page?.FAQTitle[l];
    const phoneHours = page?.PhoneHours && page?.PhoneHours[l];
    const phoneNumber = page?.PhoneNumber && page?.PhoneNumber?.iv;
    const phoneDescription = page?.PhoneDescription && page?.PhoneDescription?.iv;
    const email = page?.Email && page?.Email?.iv;
    const emailSubtitle = page?.EmailSubtitle && page?.EmailSubtitle[l];
    const emailDescription = page?.EmailDescription && page?.EmailDescription[l];

    useEffect(() => {
        console.log(data);
        setPage(data);
    }, [setPage, data]);

    if (page == null || page?.Title == null) {
        return <Spin />;
    }

    return (
        <PagePanel>
            <div className="split-page">
                <div className="page-left">
                    <div className="ln-root">
                        <PageLink text={title} to="about" />
                        <PageLink text={phoneTitle} to="phone" />
                        <PageLink text={emailTitle} to="email" />
                        <PageLink text={faqTitle} to="faq" />
                    </div>
                </div>
                <div className="page-right">
                    <div id="about">
                        <h1>{title}</h1>
                        {page?.Content && <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(page?.Content[l]) }}></p>}
                    </div>
                    <div id="phone">
                        <h1>{phoneTitle}</h1>
                        <TakeActionBox title={`${ct('Text_Phone.Text')} ${phoneNumber}`} content={phoneHours} icon="Phone" fixed={false} />
                        {page?.PhoneDescription && <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(phoneDescription) }}></p>}
                    </div>
                    <div id="email">
                        <h1>{emailTitle}</h1>
                        <TakeActionBox title={`${ct('Text_Email.Text')} ${email}`} content={emailSubtitle} icon="Envelope" fixed={false} />
                        {page?.EmailDescription && <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(emailDescription) }}></p>}
                    </div>
                    {page?.FAQ[l] && page?.FAQ[l].length > 0 && (
                        <div id="faq" className="pad-bottom">
                            <h1>{faqTitle}</h1>
                            <DefaultAccordion items={page?.FAQ[l]} />
                        </div>
                    )}
                </div>
            </div>
        </PagePanel>
    );
};

export default BoundContactPage;
