import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decode } from 'html-entities';
import { resolveIcon } from '../../util/functions';
import BoundButton from '../shared/BoundButton';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import TakeActionLink from './TakeActionLink';

const TakeActionBox = ({ title, content, link, icon, headColor, bodyColor, fixed = true }) => {
    const resolvedIcon = resolveIcon(icon);
    const visibility = link ? 'visible' : 'hidden';
    const fixedClass = fixed ? ' fixed' : '';

    const handleClick = () => {
        window.location = link;
    };

    return (
        <div className={`ta-root${fixedClass}`}>
            <div className="ta-title-root" style={{ backgroundColor: headColor }}>
                {icon && (
                    <div className="ta-title-icon">
                        <FontAwesomeIcon size="2x" icon={resolvedIcon} />
                    </div>
                )}
                <div className="ta-title-text">{title}</div>
            </div>
            <div className="ta-content-root" style={{ backgroundColor: bodyColor }}>
                <div className="ta-content" dangerouslySetInnerHTML={{ __html: decode(content) }}></div>
                <div className="ta-button-box" style={{ visibility: visibility }}>
                    <TakeActionLink text="Text_ReadMore.Text" href={link} />
                </div>
            </div>
        </div>
    );
};

TakeActionBox.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.string,
    icon: PropTypes.string,
    fixed: PropTypes.bool,
};

export default TakeActionBox;
