import AsyncStorage from '@react-native-async-storage/async-storage';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { http } from './httpRequestService';
import { StorageKeys, ContentStorageKeys } from '../constants/Storage.js';

export const ConfigureTranslation = async (resources, handleTranslationsLoaded, baseLanguage) => {
    const defaultLanguage = 'en';
    let currentLanguage = defaultLanguage;
    if (baseLanguage) {
        currentLanguage = baseLanguage;
    }

    i18n.use(initReactI18next).init({
        resources,
        lng: currentLanguage,
        fallbackLng: defaultLanguage,

        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
    });

    if (handleTranslationsLoaded) handleTranslationsLoaded();
};

const GetTranslations = async (
    baseUrl,
    countryCode,
    newVersionAvailable,
    handleTranslationsLoaded,
    handleGetCachedTranslations,
    handleTranslationsRetrieved,
    baseLanguage
) => {
    const useCache = handleGetCachedTranslations != null;
    let cachedTranslations;
    if (useCache) {
        cachedTranslations = await handleGetCachedTranslations();
    }

    if ((!useCache && cachedTranslations == null) || newVersionAvailable) {
        let textResources = {};

        http.get(`${baseUrl}Content/Text?country=${countryCode}`, async textResult => {
            textResources = textResult.data;
            ConfigureTranslation(textResources, handleTranslationsLoaded, baseLanguage);
            handleTranslationsRetrieved(textResources);
        });
    } else {
        const resources = JSON.parse(cachedTranslations);
        ConfigureTranslation(resources, handleTranslationsLoaded, baseLanguage);
    }
};

const cacheContentType = async (baseUrl, countryCode, cache, onComplete) => {
    http.get(`${baseUrl}Content/${cache.name}?country=${countryCode}`, async contentResult => {
        const contentResources = contentResult.data;
        await AsyncStorage.setItem(cache.key, JSON.stringify(contentResources));
        onComplete();
    });
};

export const CacheAllContent = async (baseUrl, countryCode, onComplete) => {
    const totalCachedCount = ContentStorageKeys.length;
    let currentCachedCount = 0;

    ContentStorageKeys.forEach(contentToCache => {
        cacheContentType(baseUrl, countryCode, contentToCache, () => {
            currentCachedCount = currentCachedCount + 1;
            if (currentCachedCount == totalCachedCount) {
                onComplete();
            }
        });
    });
};

const GetAllTranslations = (
    baseUrl,
    countryCode,
    newVersionAvailable,
    handleTranslationsLoaded,
    handleGetCachedTranslations,
    handleTranslationsRetrieved,
    baseLanguage
) => {
    GetTranslations(
        baseUrl,
        countryCode,
        newVersionAvailable,
        handleTranslationsLoaded,
        handleGetCachedTranslations,
        handleTranslationsRetrieved,
        baseLanguage
    );
};

// Usage: For the web, we don't want to check the version, or use the cache.
export const LoadTranslations = async (
    baseUrl,
    countryCode,
    handleTranslationsLoaded,
    handleGetCachedTranslations, // APP ONLY: Pass to use cache prior to querying for new translations against the API
    handleTranslationsRetrieved, // Executes after all translations have been aggregated, passes translations back
    baseLanguage // APP ONLY: Pass to pull cached languages from the cache.
) => {
    GetAllTranslations(baseUrl, countryCode, false, handleTranslationsLoaded, handleGetCachedTranslations, handleTranslationsRetrieved, baseLanguage);
};

export const LoadWebTranslations = (baseUrl, countryCode, baseLanguage, onTranslationsRetrieved) => {
    LoadTranslations(baseUrl, countryCode, null, null, onTranslationsRetrieved, baseLanguage);
};
