import useConfigContext from './useConfigContext';

// TODO: Update this to pull from a context after we decide how we want to store the country
export default function useActiveCountry() {
    // const { country } = useConfigContext();
    // const defaultCountry = 'UK';
    // const c = country != null && country != undefined ? country : defaultCountry;

    return 'UK';
}
