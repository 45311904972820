// eslint-disable-next-line import/no-unresolved
import axios from 'axios';

export const http = {
    get: (uri, handleGetComplete, onError) => {
        const fullUri = uri;
        console.log('Api GET:', fullUri);
        axios
            .get(fullUri)
            .then(response => {
                if (handleGetComplete) handleGetComplete(response);
            })
            .catch(err => {
                if (onError) {
                    console.log(err);
                    onError(err);
                }
            });
    },
    post: (uri, body, handleGetComplete, onError) => {
        const fullUri = uri;
        axios
            .post(fullUri, body)
            .then(response => {
                if (handleGetComplete) handleGetComplete(response);
            })
            .catch(err => {
                if (onError) {
                    onError(err);
                }
            });
    },
};

export default http;
