import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import i18n from 'i18next';
import Routes from './features/navigation/Routes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as i18nService from './services/i18nService';
import { getCountrySubdomain, getLangFromUrl } from './util/functions';
import useConfig from './config/hooks/useConfig';

import 'antd/dist/antd.css';
import { Spin } from 'antd';
import { QuestionnaireContextProvider } from './features/shared/contexts/QuestionnaireContext';
require('./assets/images/favicon.ico');

function App() {
    const [loadingTranslations, setLoadingTranslations] = useState(true);
    const [loadingCache, setLoadingCache] = useState(true);
    const config = useConfig();

    const theme = createTheme({
        palette: {
            primary: {
                main: '#2b4570',
            },
            secondary: {
                main: '#407b82',
            },
        },
    });

    // Adding a simple redirect to /en/home
    if (window.location.pathname === '/' || window.location.pathname === '/en' || window.location.pathname === '/en/') {
        window.location = '/en/home';
    }

    // TO DO: We need to set a default translation country to use before the country is selected
    //       So that we're able to pull the config page translations.
    //       After selection, the proper country code is code during translation.
    const sCountry = getCountrySubdomain();
    const defaultCountryCode = sCountry == null ? 'UK' : sCountry;

    const urlLang = getLangFromUrl();
    const setLang = () => {
        if (urlLang) {
            i18n.changeLanguage(urlLang);
        }
    };

    useEffect(() => {
        i18nService.LoadWebTranslations(config.apiUrl, defaultCountryCode, urlLang, translations => {
            for (const [key, value] of Object.entries(translations)) {
                i18n.addResourceBundle(key, 'translation', value.translation);
            }

            setLang();
            setLoadingTranslations(false);
        });
        i18nService.CacheAllContent(config.apiUrl, defaultCountryCode, () => {
            setLoadingCache(false);
        });
    }, [setLoadingTranslations, setLoadingCache]);

    return (
        <div>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <QuestionnaireContextProvider>
                        {!loadingTranslations && !loadingCache && <Routes />}
                        {loadingTranslations && loadingCache && (
                            <Spin style={{ height: '100vh' }} size="large" style={{ marginTop: 50, height: '100vh' }} />
                        )}
                    </QuestionnaireContextProvider>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
