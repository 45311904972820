import React from 'react';
import { Route } from 'react-router-dom';
import TopNavigation from './TopNavigation';
import Footer from './Footer';
import BoundPage from '../shared/BoundPage';
import BoundHomePage from '../shared/BoundHomePage';
import BoundContactPage from '../shared/BoundContactPage';
import BoundQuestionnairePage from '../shared/BoundQuestionnairePage';
import TopAppBar from './TopAppBar';

const Routes = () => {
    return (
        <>
            <TopAppBar />
            <Route exact path="/" component={BoundHomePage} />
            <Route exact path="/home" component={BoundHomePage} />
            <Route exact path="/:lang/home" component={BoundHomePage} />
            <Route exact path="/:lang/contact" component={BoundContactPage} />
            <Route path="/:lang/pages/:pageName" component={BoundPage} />
            <Route path="/:lang/questionnaires/:pageName" component={BoundQuestionnairePage} />
            <Footer />
        </>
    );
};

export default Routes;
