import React from 'react';
import { Layout } from 'antd';
import './css/footer.less';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from '../shared/hooks/useActiveCountry';

const { Header } = Layout;

function Footer() {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);

    return (
        <Header className="header">
            <div className="menu">
                <div className="footer-main"></div>
                <div className="right-menu">
                    <a href={ct('Url_PrivacyPolicyLink.Text')}>{ct('Text_PrivacyPolicyLink.Text')}</a>
                    <a style={{ display: 'inline-block', marginLeft: 8 }} href={ct('Url_FooterLink.Text')}>
                        {ct('Text_FooterLink.Text')}
                    </a>
                    <img
                        height={40}
                        style={{ alignSelf: 'center', marginBottom: 15, marginLeft: 12 }}
                        alt="Hestia Logo"
                        src={require('../../assets/images/hestia-logo.png')}
                    />
                </div>
            </div>
        </Header>
    );
}

export default Footer;
