import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { Drawer } from 'antd';
import { Close, Menu } from '@mui/icons-material';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import BoundButton from '../shared/BoundButton';

const NavigationDrawer = ({ navLinks }) => {
    const c = useActiveCountry();
    const { ct, l } = useCountryTranslation(c);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const handleNavigate = () => {
        window.scrollTo(0, 0);
        setOpen(false);
    };

    const onLinkClick = () => {
        window.location.href = 'https://bbc.com';
    };

    return (
        <>
            <IconButton size="large" aria-label="open menu" aria-controls="menu-appbar" aria-haspopup="true" onClick={showDrawer} color="inherit">
                <Menu htmlColor="#FFFFFF" />
            </IconButton>

            <Drawer placement="left" closable={false} onClose={onClose} visible={open} key="main-drawer">
                <div className="close-drawer">
                    <IconButton onClick={onClose} ariaLabel="Close Menu">
                        <Close />
                    </IconButton>
                </div>
                {navLinks?.map((n, i) => {
                    return (
                        <div key={i.toString()} className="r2a-menuitem-mobile">
                            <Link to={`/${l}/pages/${n.url}`} onClick={handleNavigate}>
                                {n.title}
                            </Link>
                        </div>
                    );
                })}
                <div className="r2a-mobile-divider"></div>
                <div className="r2a-submenu">
                    <div className="r2a-menuitem-mobile">
                        <Link to={`/${l}/contact`} onClick={handleNavigate}>
                            <FontAwesomeIcon icon={faEnvelope} /> {ct('Text_ContactAdviceLine.Text')}
                        </Link>
                    </div>
                    <div style={{ marginLeft: -8, marginTop: 6 }}>
                        <BoundButton className="secondary-bg" text={ct('Text_QuickExit.Text')} onClick={onLinkClick} padded={false} />
                    </div>
                </div>
            </Drawer>
        </>
    );
};

NavigationDrawer.propTypes = {
    navLinks: PropTypes.array,
};

export default NavigationDrawer;
