export const StorageKeys = {
    TranslationKey: 'PrimaryTranslationStore',
    CurrentGuideKey: 'CurrentGuideStore',
    CurrentLanguageKey: 'CurrentLanguageStore',
    QuestionnaireContentKey: 'QuestionnaireContentStore',
    PageContentKey: 'PageContentStore',
    ContactPageKey: 'ContactPageStore',
};

// USAGE: Adding storage keys here will automatically cache
//        the content on app startup
//        The "name" property should match that of the endpoint.
export const ContentStorageKeys = [
    { key: StorageKeys.QuestionnaireContentKey, name: 'Questionnaires' },
    { key: StorageKeys.PageContentKey, name: 'Pages' },
    { key: StorageKeys.ContactPageKey, name: 'Pages/Contact' },
];
