import { useContext } from 'react';
import { QuestionnaireContext } from '../contexts/QuestionnaireContext';

const useQuestionnaireContext = () => {
    const context = useContext(QuestionnaireContext);
    if (!context) {
        throw new Error('useQuestionnaireContext must be used within a QuestionnaireContextProvider');
    }
    return context;
};

export default useQuestionnaireContext;
