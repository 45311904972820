import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PageLink = ({ to, text }) => {
    return (
        <Link className="ln-link" to={to} smooth={true} offset={-75} spy={true}>
            {text}
            <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </Link>
    );
};

PageLink.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default PageLink;
