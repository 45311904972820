import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import useActiveCountry from '../shared/hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';
import useCacheStore from '../shared/hooks/useCacheStore';
import { StorageKeys } from '../../constants/Storage';
import NavigationDrawer from './NavigationDrawer';
import SubNavigation from './SubNavigation';

const TopAppBar = () => {
    const c = useActiveCountry();
    const { l } = useCountryTranslation(c);
    const location = useLocation();
    const { data } = useCacheStore(StorageKeys.PageContentKey);
    const [navLinks, setNavLinks] = useState([]);

    useEffect(() => {
        const newNavLinks = [];
        for (const [key, value] of Object.entries(data)) {
            const pageData = value;
            if (value?.IsHome == 'False' && value?.DisplayInNavigation == 'True') {
                newNavLinks.push({
                    title: pageData?.Title,
                    url: pageData?.Url,
                });
            }
        }
        setNavLinks(newNavLinks);
    }, [setNavLinks, data]);

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div style={{ position: 'fixed', width: '100%', zIndex: 1000 }}>
                <AppBar position="static">
                    <Toolbar>
                        <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
                            <Link to={`/${l}/home`} onClick={scrollToTop}>
                                <img height={50} src={require('../../assets/images/logo.png')} />
                            </Link>
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <NavigationDrawer navLinks={navLinks} />
                        </Box>

                        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <Link to={`/${l}/home`} onClick={scrollToTop}>
                                <img height={50} src={require('../../assets/images/logo.png')} />
                            </Link>
                        </Typography>

                        <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', md: 'flex' } }}>
                            {navLinks?.map((n, i) => {
                                const navUrl = `/${l}/pages/${n.url}`;
                                const className = navUrl === location.pathname ? 'bs-menu-item active' : 'bs-menu-item';
                                return (
                                    <Link key={i.toString()} to={navUrl} onClick={scrollToTop} className={className}>
                                        {n.title}
                                    </Link>
                                );
                            })}
                        </Box>
                    </Toolbar>
                </AppBar>
                <div id="subheader">
                    <SubNavigation />
                </div>
            </div>
            <div style={{ height: 70 }}></div>
        </>
    );
};
export default TopAppBar;
