import localConfig from '../config.local.json';
import devConfig from '../config.dev.json';
import qaConfig from '../config.qa.json';
import prodConfig from '../config.prod.json';

const isEnv = prefix => {
    const hostname = window.location.hostname;
    return hostname.indexOf(prefix) > -1;
};

const getConfig = () => {
    const isLocal = isEnv('localhost');
    const isDev = isEnv('dev.') || isEnv('-dev');
    const isQa = isEnv('qa.') || isEnv('-qa');
    const isProd = isEnv('respondtoabuse.org');

    if (isLocal) {
        return localConfig;
    } else if (isDev) {
        return devConfig;
    } else if (isQa) {
        return qaConfig;
    } else if (isProd) {
        return prodConfig;
    }
};

export default getConfig;
