import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useCacheStore from './hooks/useCacheStore';
import { StorageKeys } from '../../constants/Storage';
import { Spin } from 'antd';
import PagePanel from './PagePanel';
import './css/boundpage.less';
import BoundQuestionnaire from './BoundQuestionnaire';
import useActiveCountry from './hooks/useActiveCountry';
import { useCountryTranslation } from 'brightsky-3';

const BoundQuestionnairePage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { pageName } = useParams();
    const [questionnaire, setQuestionnaire] = useState();
    const [searched, setSearched] = useState(false);
    const { data } = useCacheStore(StorageKeys.QuestionnaireContentKey);
    const notFound = searched === true && questionnaire == null;

    useEffect(() => {
        for (const [key, value] of Object.entries(data)) {
            const questData = value;
            if (questData?.Url == pageName) {
                setQuestionnaire(questData);
            }
            setSearched(true);
        }
    }, [pageName, data, setQuestionnaire]);

    if (questionnaire == null && searched == false) {
        return (
            <div className="flex-default">
                <Spin />
            </div>
        );
    }

    if (notFound) {
        return (
            <div style={{ minHeight: 600, marginLeft: 32 }} className="flex-default">
                <h1>{ct('Text_QuestionnaireNotFound.Text')}</h1>
            </div>
        );
    }

    return (
        <PagePanel>
            <BoundQuestionnaire binding={questionnaire} />
        </PagePanel>
    );
};

export default BoundQuestionnairePage;
