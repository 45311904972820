import * as Icons from '@fortawesome/pro-regular-svg-icons';

export const isEmptyObject = objectToTest => {
    return JSON.stringify(objectToTest) === '{}';
};

export const getCountrySubdomain = () => {
    const domainParts = window.location.host.split('.');
    const subdomain = domainParts.length === 3 ? domainParts[0] : null;

    const hasCountryInUrl =
        subdomain != null &&
        domainParts.length === 3 &&
        subdomain.toLowerCase() !== 'www' &&
        subdomain.toLowerCase() !== 'dev' &&
        subdomain.toLowerCase() !== 'qa';
    let sCountry = hasCountryInUrl ? subdomain : null;

    if (subdomain != null && hasCountryInUrl)
        sCountry = subdomain?.indexOf('-') != -1 ? subdomain.split('-')[1].toUpperCase() : subdomain.toUpperCase();

    //TODO: Update this post domain-binding
    console.warn('PENDING IMPLEMENTATION:', '/util/functions.js > getCountrySubdomain');

    return 'UK';
    return sCountry;
};

export const hasCountryInDomain = () => {
    const domainParts = window.location.host.split('.');
    const subdomain = domainParts.length === 3 ? domainParts[0] : null;

    const hasCountryInUrl =
        subdomain != null &&
        domainParts.length === 3 &&
        subdomain.toLowerCase() !== 'www' &&
        subdomain.toLowerCase() !== 'dev' &&
        subdomain.toLowerCase() !== 'qa';

    //TODO: Update this post domain-binding
    console.warn('PENDING IMPLEMENTATION:', '/util/functions.js > hasCountryInDomain');

    return true;
    return hasCountryInUrl;
};

export const getLangFromUrl = () => {
    return window.location.pathname.split('/')[1];
};

export const resolveIcon = (name, assetUri) => {
    const customIconKey = '*UseAsset*';

    if (name && name !== customIconKey) {
        const fullIconName = `fa${name}`;
        const icon = Icons[fullIconName];
        return icon;
    }

    if (name && name === customIconKey && assetUri) {
        return {
            isCustom: true,
            uri: assetUri,
        };
    }

    return null;
};
