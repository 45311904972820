import { StorageKeys, useCountryTranslation } from 'brightsky-3';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { decode } from 'html-entities';
import useActiveCountry from './hooks/useActiveCountry';
import PagePanel from './PagePanel';
import BoundButton from './BoundButton';
import useContentContext from './hooks/useContentContext';
import useQuestionnaireContext from './hooks/useQuestionnaireContext';
import PossibleAnswers from './PossibleAnswers';
import useConfigContext from './hooks/useConfigContext';
import './css/boundquestionnaire.less';

const BoundQuestionnaire = ({ binding }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { currentQuestionIndex, reset, startQuestionnaire, isComplete, correctAnswerCount, answers, scoreQuestionnaire } =
        useQuestionnaireContext();
    const [currentQuestionnaire, setCurrentQuestionnaire] = useState();
    const [showLearnMore, setShowLearnMore] = useState('');
    const complete = isComplete();
    let correctPercent = 0;
    let closestResult = '';
    const hasResults = currentQuestionnaire?.Results != null && currentQuestionnaire?.Results?.length > 0;

    if (complete && hasResults) {
        const results = currentQuestionnaire.Results;
        correctPercent = Math.round((correctAnswerCount / answers.length) * 100);
        scoreQuestionnaire();
        closestResult =
            results != null &&
            results.length > 0 &&
            results.reduce((result1, result2) =>
                Math.abs(result2.ResultThreshold - correctPercent) < Math.abs(result1.ResultThreshold - correctPercent) ? result2 : result1
            );
    }

    useEffect(() => {
        setCurrentQuestionnaire(binding);
    }, [setCurrentQuestionnaire, binding]);

    useEffect(() => {
        if (currentQuestionnaire != null) {
            startQuestionnaire(currentQuestionnaire);
        }
    }, [currentQuestionnaire]);

    const bind = t => decode(t);

    const questions =
        currentQuestionnaire != null
            ? currentQuestionnaire.Questions.map((q, i) => {
                  const display = currentQuestionIndex >= i ? 'block' : 'none';
                  const isCurrentQuestion = currentQuestionIndex === i ? 'current' : '';

                  const hasAnswerNotifications =
                      q.CorrectNotification != null &&
                      q.CorrectNotification != '' &&
                      q.IncorrectNotification != null &&
                      q.IncorrectNotification != '';
                  const hasAnswered = answers[i] != null;

                  const hasLearnMoreInfo = q.LearnMore != null && q.LearnMore != '';
                  let learnMoreContent = <div dangerouslySetInnerHTML={{ __html: bind(q.LearnMore) }}></div>;
                  learnMoreContent = hasLearnMoreInfo ? learnMoreContent : null;
                  let responseContent =
                      answers[i] === q.Answer ? (
                          <div className="q-notification" dangerouslySetInnerHTML={{ __html: bind(q.CorrectNotification) }}></div>
                      ) : (
                          <div className="q-notification" dangerouslySetInnerHTML={{ __html: bind(q.IncorrectNotification) }}></div>
                      );
                  responseContent = hasAnswerNotifications && hasAnswered ? responseContent : null;
                  return (
                      <div key={i.toString()} className={`question ${isCurrentQuestion}`} style={{ display: display }}>
                          <div className="content">
                              <div className="left">{i + 1}.</div>
                              <div className="right">
                                  <div dangerouslySetInnerHTML={{ __html: bind(q.Question) }}></div>
                                  <br />
                                  {showLearnMore === i.toString() && learnMoreContent}
                                  {responseContent}
                              </div>
                          </div>

                          <div className="answers">
                              {learnMoreContent && (
                                  <BoundButton
                                      disabled={showLearnMore === i.toString()}
                                      text={decode(ct('Text_Label_UnderstandThisQuestion.Text'))}
                                      onClick={() => setShowLearnMore(i.toString())}
                                      type="secondary"
                                  />
                              )}
                              <div className="possible-answers">
                                  <PossibleAnswers qIndex={i} possibleAnswers={currentQuestionnaire.PossibleAnswers} answers={answers} />
                              </div>
                          </div>
                      </div>
                  );
              })
            : [];

    const handleStartOver = () => {
        reset();
    };

    const showResults = complete && currentQuestionnaire?.Results != null && hasResults && closestResult != null;

    return (
        <PagePanel background="#FFFFFF">
            <div className="questionnaire">
                <h3>{bind(currentQuestionnaire?.Title)}</h3>
                {questions}
                {showResults && (
                    <div className="results">
                        <h3>{decode(ct('Title_Summary.Text'))}</h3>
                        <p dangerouslySetInnerHTML={{ __html: bind(closestResult.WebResultContent) }}></p>
                    </div>
                )}
                <div className="actions">{complete && <BoundButton binding="Button_StartOver.Text" onClick={handleStartOver} />}</div>
            </div>
        </PagePanel>
    );
};

BoundQuestionnaire.propTypes = {
    binding: PropTypes.string,
};

export default BoundQuestionnaire;
