import React from 'react';
import PropTypes from 'prop-types';
import { useCountryTranslation } from 'brightsky-3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useActiveCountry from './hooks/useActiveCountry';
import { faEye } from '@fortawesome/pro-solid-svg-icons';

const TakeActionLink = ({ text, href }) => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);

    return (
        <a href={href} alt={ct(text)} className="ta-link">
            <FontAwesomeIcon icon={faEye} />
            {ct(text)}
        </a>
    );
};

TakeActionLink.propTypes = {
    text: PropTypes.string,
    href: PropTypes.string,
};

export default TakeActionLink;
