import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useCacheStore from './hooks/useCacheStore';
import { StorageKeys } from '../../constants/Storage';
import { decode } from 'html-entities';
import { Spin } from 'antd';
import TakeActionBox from './TakeActionBox';
import { useCountryTranslation } from 'brightsky-3';
import useActiveCountry from './hooks/useActiveCountry';
import ContentSection from './ContentSection';
import DefaultAccordion from './DefaultAccordion';
import PagePanel from './PagePanel';
import HeroBox from './HeroBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import './css/boundpage.less';

const BoundHomePage = () => {
    const c = useActiveCountry();
    const { ct } = useCountryTranslation(c);
    const { pageName } = useParams();
    const { data } = useCacheStore(StorageKeys.PageContentKey);
    const [page, setPage] = useState();

    useEffect(() => {
        for (const [key, value] of Object.entries(data)) {
            const pageData = value;
            if (pageData?.IsHome == 'True') {
                setPage(pageData);
            }
        }
    }, [setPage, data, pageName]);

    if (page == null) {
        return <Spin />;
    }

    return (
        <>
            <PagePanel>
                <div id="about">
                    <h1>{page?.Title}</h1>
                    {page?.Content && <p className="pad-bottom" dangerouslySetInnerHTML={{ __html: decode(page?.Content) }}></p>}
                </div>
            </PagePanel>

            <PagePanel>
                {page?.TakeAction && page?.TakeAction.length > 0 && (
                    <div id="takeaction">
                        <div className="ta pad-bottom">
                            {page?.TakeAction.map((t, i) => {
                                return (
                                    <TakeActionBox
                                        key={i.toString()}
                                        title={decode(t?.Title)}
                                        content={decode(t?.Description)}
                                        headColor={t?.HeaderColor}
                                        bodyColor={t?.BodyColor}
                                        link={t?.Link}
                                        icon={t?.Icon}
                                    />
                                );
                            })}
                        </div>
                    </div>
                )}
            </PagePanel>

            {page?.HeroItems && page?.HeroItems.length > 0 && (
                <PagePanel background="#F1F1F1">
                    <div className="hero-inner">
                        <h4 className="hero-title">{decode(page?.HeroTitle)}</h4>
                        <div className="hb">
                            {page?.HeroItems?.map((t, i) => {
                                return <HeroBox altText={decode(t?.AltText)} image={decode(t?.Image[0])} />;
                            })}
                        </div>
                    </div>
                    {page?.HeroMoreInfoLink && (
                        <div className="hero-more-info">
                            <a href={page?.HeroMoreInfoLink} target="_blank">
                                {ct('Text_MoreInfo.Text')}
                            </a>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                    )}
                </PagePanel>
            )}

            <PagePanel>
                {page?.PageSections?.map((t, i) => {
                    return (
                        <div className="pad-bottom">
                            <ContentSection key={i.toString()} title={t?.Title} content={t?.Content} />
                        </div>
                    );
                })}

                {page?.Checklist && page?.Checklist.length > 0 && (
                    <div id="checklist" className="pad-bottom">
                        <h1>{ct('Text_Checklist.Text')}</h1>
                        <DefaultAccordion items={page?.Checklist} />
                    </div>
                )}

                <div className="addtl-content pad-bottom">
                    <br />
                    {page?.AdditionalContent && <p dangerouslySetInnerHTML={{ __html: decode(page?.AdditionalContent) }}></p>}
                </div>
            </PagePanel>
        </>
    );
};

export default BoundHomePage;
